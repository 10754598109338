/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, lang, meta, title, image }) {
  const { site } = useStaticQuery(query)

  const {
    siteTitle,
    titleTemplate,
    siteDescription,
    siteUrl,
    defaultImage
  } = site.siteMetadata

  const seo = {
    title: siteTitle,
    titleTemplate: `${siteTitle} | %s`,
    description: description || siteDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}`,
  }

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
    <meta name="description" content={seo.description} />
    <meta name="image" content={'/img/og-image.png'} />

    {seo.url && <meta property="og:url" content={seo.url} />}

    {seo.title && <meta property="og:title" content={seo.title} />}

    {seo.description && (
      <meta property="og:description" content={seo.description} />
    )}

    {seo.image && <meta property="og:image" content={'/img/og-image.png'} />}
    <meta name="twitter:card" content="summary_large_image" />

    {seo.title && <meta name="twitter:title" content={seo.title} />}
    {seo.description && (
      <meta name="twitter:description" content={seo.description} />
    )}

    {seo.image && <meta name="twitter:image" content={'/img/og-image.png'} />}

    <link href="https://fonts.googleapis.com/css?family=Lato:400,700,900|Merriweather:400,700,900" rel="stylesheet" />
    </Helmet>
  )
}

Seo.defaultProps = {
  title: null,
  description: null,
  image: null,
  lang: `en`,
  meta: [],
}

Seo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}

const query = graphql`
  query {
    site {
      siteMetadata {
        siteTitle: title
        siteDescription: description
        author
        defaultImage: image
        siteUrl
      }
    }
  }
`

export default Seo
